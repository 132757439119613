* {
    padding: 0;
    margin: 0;
    border: 0;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a {
    color: #030b57;
    text-decoration: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    font-size: 16px !important;
    line-height: 1;
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
    background: transparent;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

p {
    font-size: 16px;
}

/*--------------------*/
body {
    font-family: "Ubuntu", sans-serif;
    background: #f8f8f8;
}

.container {
    min-width: 100%;
    padding: 0 30px;
}

/* main {
  margin-top: 160px;
} */

@media (max-width: 678px) {
    .container {
        padding: 0 10px;
    }
}

.nav-icon {
    display: block;
    width: 30px;
    height: 18px;
    position: relative;
    cursor: pointer;
}

.nav-icon::after,
.nav-icon::before,
.nav-icon span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 14%;
    background: #000;
}

.nav-icon::after {
    bottom: 0;
}

.nav-icon span {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.nav-icon::before {
    top: 0;
}

.headers {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 30;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0 0px 5px #888;
    box-shadow: 0 0px 5px #888;
}

.headers__icon {
    width: 15px;
}

.headers_close_icon {
    width: 40px;
}

.headers___icon {
    width: 25px;
}

.headers____icon {
    width: 20px;
    margin-top: -5px;
}

.headers_social_icon {
    width: 30px;
}

.headers_social_icon_tg {
    width: 25px;
}


.headers__info {
    background: #f3bb04;
    padding: 10px 25px;
    font-size: 16px;
}

@media (max-width: 1000px) {
    .headers__info {
        background: #f3bb04;
        padding: 15px 17px;
        font-size: 16px;
    }
}

.headers__info .containers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.headers__info a {
    color: #000;
    cursor: pointer;
}

.headers__location {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.headers__location img {
    margin-right: 10px;
}

address {
    font-style: normal;
}

.headers__connection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.headers__connection img {
    margin-right: 10px;
}

.headers__tel {
    margin-right: 20px;
}

.headers__icons {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.compare-fa_icon {
    margin-left: 7px;
}

.headers__icons-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    padding: 0 10px;
    border-left: 1px solid #ccc;
}

.headers__icons-item span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.headers__icons-item button {
    font-size: 20px;
}

.headers__icons-item img {
    max-width: 23px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.headers__icon-search {
   width: 20px !important;
}

.headers__icons-item img:hover {
    opacity: 0.7;
}

.headers__icons-item img:active {
    opacity: 0.3;
    -webkit-transition: all 0.01s;
    transition: all 0.01s;
}

.headers__respons {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-around;
    width: 100%;
}

@media (max-width: 1020px) {
    .headers__respons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .headers__icons,
    .headers__connection,
    .headers__location {
        display: none;
    }
}

.search-input {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(20px);
    transform: translate(20px);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    right: 100%;
}

.search-input._open {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0px);
    transform: translate(0px);
}

.search-input input {
    padding: 5px 15px;
    padding-right: 30px;
    font-size: 14px;
    border: 2px solid #888;
    border-radius: 5px;
}

.search-input input:focus {
    opacity: 1;
    border: 2px solid #083aff;
}

.search-input span {
    width: 23px;
    height: 23px;
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 2;
}

.search-input span::before,
.search-input span::after {
    width: 90%;
    height: 2px;
    content: "";
    position: absolute;
    background: #000;
}

.search-input span::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.search-input span::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.menu {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: fixed;
    width: 270px;
    z-index: 7;
    height: 100vh;
    background: #222;
    right: 0;
    top: 0;
    color: #fff;
    padding: 20px;
    font-size: 14px;
    min-height: 100%;
    overflow-y: auto;
}

.menu a {
    color: #fff;
}

.menu._open {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
}

.menu__title {
    background-color: transparent;
    color: #ebd300;
    font-size: 24px;
    margin-bottom: 10px;
}

.menu__close {
    position: absolute;
    right: 15px;
    top: 15px;
}

.menu__block {
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
}

.menu__block-1 {
    border-top: 0;
}

.menu__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 5px 0;
    padding: 2px 0;
}

.menu__link > p {
    margin-bottom: 0;
}

.headers__link {
    display: block;
    margin: 5px 0;
    padding: 2px 0;
}

.menu__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.menu__social-icon {
    padding: 2px;
    margin: 2px;
}

.menu__block-location {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 150%;
}

.menu__block-location address {
    margin-left: 10px;
}

.headers__main .container {
    padding-left: 50px;
    padding-right: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.dropbtn {
    background-color: transparent;
    color: #000;
    padding: 8px;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
    margin-right: 8px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content span {
    padding: 5px 7px;
}

.dropdown-content a:hover {
    background-color: #dddddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #e2c001;
}

.dropdown_time {
    margin-left: 15px;
}

.dropbtn > span {
    margin: 5px;
    font-size: 20px;
    color: #000;
}

.dropdown-content > span {
    color: #000 !important;
}

.dropbtn_color,
.dropbtn_color > span {
    color: #fff;
}

@media (max-width: 800px) {
    .headers__main .container {
        padding-left: 0.1px;
        padding-right: 0.1px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.headers__logo {
    padding: 5px 0;
    max-width: 170px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.headers__logo img {
    width: 100%;
}

.headers__nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%;
}

.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-left: 20px;
}

.headers__nav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 15px 0 0;
    opacity: 0.7;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

.headers__nav-item > p {
    margin-bottom: 5px;
}

.headers__nav-item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 4px;
    border-radius: 50px;
    background: #f3bb04;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.headers__nav-item:hover {
    opacity: 1;
}

.headers__nav-item:hover::after {
    width: 100%;
}

.headers__nav-icon {
    width: 36px;
    height: 36px;
    background: #bbb;
    border-radius: 50%;
    margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.headers__nav-icon .material-symbols-outlined {
    font-size: 26px;
    color: #555;
}
.iconCol{
    font-size: 24px;
    color: #4D4D4D!important;
}
.headers__nav-link {
    padding: 20px 0;
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.headers__button {
    background: #f3bb04;
    font-size: 14px;
    padding: 10px 15px;
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin: 20px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.headers__button:hover {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
    -webkit-box-shadow: 2px 2px 7px #777;
    box-shadow: 2px 2px 7px #777;
}

.headers__button:active {
    -webkit-box-shadow: 2px 2px 2px #777;
    box-shadow: 2px 2px 2px #777;
}

.button-icon-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.headers__respons-nav {
    display: none;
    margin-right: 10px;
}

@media (max-width: 1024px) {
    .nav {
        display: none;
    }

    .headers__respons-nav {
        display: block;
    }

    .headers__button {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .map-address > iframe {
        height: 250px !important;
    }
}

@media (max-width: 879px) {
    .headers__respons-nav {
        display: none;
    }
}

@media (max-width: 520px) {
    .button-icon-block {
        display: none;
        margin: 0 auto;
    }

    .header__logo img {
        height: 60px;
    }

    .headers__main .container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.slider__title {
    display: flex;
    font-size: 18px;
    background: #f3bb04;
    width: 100%;
    text-align: center;
    line-height: 150%;
    overflow: hidden;
}

.slider__title span {
    display: block;
    margin: 0 auto;
    padding: 5px 0;
    color: #aa2d2d;
    width: 100%;
    white-space: nowrap;
    -webkit-animation: transform_x 7s linear infinite alternate;
    animation: transform_x 7s linear infinite alternate;
}

.slider {
    background: url(../assets/img/slider-wraper.jpg) center/cover no-repeat;
}

.swiper-slide a {
    display: block;
}

.swiper-slide img {
    /*max-width: 540px;*/
    display: block;
    margin-left: auto;
}

.swiper-pagination .swiper-pagination-bullet {
    background: transparent;
    border: 1px solid #fff;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    width: 10px;
    height: 10px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.swiper-pagination .swiper-pagination-bullet:hover,
.swiper-pagination .swiper-pagination-bullet-active {
    background: #fff;
}

.cards-slider div.swiper-pagination,
.clients div.swiper-pagination {
    bottom: 0;
}

.cards-slider div.swiper-pagination .swiper-pagination-bullet,
.clients div.swiper-pagination .swiper-pagination-bullet {
    border: 1px solid #888;
}

.cards-slider div.swiper-pagination .swiper-pagination-bullet:hover,
.cards-slider div.swiper-pagination .swiper-pagination-bullet-active,
.clients div.swiper-pagination .swiper-pagination-bullet:hover,
.clients div.swiper-pagination .swiper-pagination-bullet-active {
    background: #888;
}

.swiper-slide > .cards-slider__card {
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
}

.swiper-navigation {
    font-size: 14px;
}

body .swiper-button-next:after,
body .swiper-button-prev:after {
    display: none;
}

.swiper-button-prev,
.swiper-button-next {
    background-color: rgba(162, 213, 247, 0.534);
}

@-webkit-keyframes transform_x {
    0% {
        -webkit-transform: translateX(-400px);
        transform: translateX(-400px);
    }

    100% {
        -webkit-transform: translateX(400px);
        transform: translateX(400px);
    }
}

@keyframes transform_x {
    0% {
        -webkit-transform: translateX(-400px);
        transform: translateX(-400px);
    }

    100% {
        -webkit-transform: translateX(400px);
        transform: translateX(400px);
    }
}

.swiper-slide-small {
    display: none;
}

.clients .swiper-pagination {
    display: block;
}

.slider__container {
    min-width: 0;
    position: relative;
}

.row-slide {
    padding-left: 50px;
    padding-right: 50px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}

@media (max-width: 800px) {
    .row-slide {
        -ms-flex-wrap: wrap;
        padding-left: 0.1px;
        padding-right: 0.1px;
        flex-wrap: wrap;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;
    }
}

.slider__body {
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 40px;
    color: #555;
    background: transparent;
    max-width: 540px;
    position: relative;
}
.slider__body1 {
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 40px;
    color: #555;
    background: transparent;
    max-width: 540px;
    position: relative;
    z-index: 10!important;
}

.slider__body-title {
    font-size: 46px;
    background-color: transparent;
    font-weight: 700;
    line-height: 150%;
}

.slider__body-descr {
    margin-top: 20px;
    font-size: 19px;
    line-height: 160%;
}

.slider__body-descr span {
    color: #f3bb04;
}

.slider__body-descr a {
    color: #0151bb;
}

button.slider__body-button {
    font-weight: bold;
    display: inline-block;
    background: #f3bb04;
    margin-top: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 3px;
    -webkit-box-shadow: 10px -7px 24px -12px rgba(140,108,13,1);
-moz-box-shadow: 10px -7px 24px -12px rgba(140,108,13,1);
box-shadow: 10px -7px 24px -12px rgba(140,108,13,1);
}

button.slider__body-button a {
    padding: 12px 20px;
    display: block;
}

button.slider__body-button a:hover {
    color: #1808a8;
}

button.slider__body-button:active {
    opacity: 0.3;
    -webkit-transition: all 0.01s;
    transition: all 0.01s;
}

.slider .swiper-pagination {
    display: none;
}

@media (max-width: 620px) {
    div.slider__body {
        padding-left: 20px;
    }

    .slider__body-title {
        font-size: 32px;
        line-height: 130%;
    }

    .swiper-slide img {
        max-width: 320px;
        position: relative;
    }
}

@media (min-width: 1001px) {
    .slider .swiper-pagination {
        display: block;
    }

    .slider__body {
        position: absolute;
        left: 0px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .slider__body1 {
        position: absolute;
        left: 0px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.social__title {
    padding-top: 20px;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px;
}

.social__title span {
    width: 100%;
    height: 1px;
    background: #eee;
    position: absolute;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    display: block;
}

.social__title h2 {
    position: relative;
    z-index: 2;
    padding: 0 10px;
}

.homepage-titles {
    background-color: #fff;
}

.social__row {
    display: block;
    font-size: 24px;
    line-height: 150%;
    padding: 10px;
    background: #fff;
    color: #666;
    -webkit-box-shadow: 0 5px 15px rgba(4, 4, 4, 0.08);
    box-shadow: 0 5px 15px rgba(4, 4, 4, 0.08);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.social__block {
    margin-right: 10px;
}

.social__block span {
    color: #f0506e;
}

.social__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.social__button {
    display: block;
    text-align: center;
    margin: 10px;
    padding: 10px 20px;
    width: 160px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #eee;
}

.social__button:hover {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
    -webkit-box-shadow: 2px 2px 7px #777;
    box-shadow: 2px 2px 7px #777;
}

.social__button:active {
    -webkit-box-shadow: 2px 2px 2px #777;
    box-shadow: 2px 2px 2px #777;
}

.social__button:nth-child(1) {
    background: #793baa;
}

.social__button:nth-child(2) {
    background: #2aabed;
}

.social__button:nth-child(3) {
    background: #34a853;
}

.social__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.social__btm-icon {
    height: 18px;
    margin-right: 10px;
}

.social__btm-icon img {
    width: 100%;
    height: 100%;
}

@media (max-width: 910px) {
    .social__row {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    div.social__buttons {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.cards {
    padding-bottom: 40px;
}

.cards .material-symbols-outlined {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
}

.cards__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-bottom: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 5%;
}

.cards__card {
    margin: 20px;
}

.card {
    background: #fff;
    padding: 30px 15px;
    /*max-width: 450px;*/
    text-align: center;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.card__img {
    margin-bottom: 10px;
}

.card__img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.card__title {
    color: #1e87f0;
    display: block;
    line-height: 150%;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 300;
}

.card__info {
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    line-height: 180%;
    flex-direction: column;
    row-gap: 10px;
}

.gear-img {
    margin-left: 8px;
    width: 23px !important;
}

.gear-img_card {
    max-width: 20px;
    width: 100%;
}

.card__item {
    line-height: 150%;
    margin: 0 0;
}

.card__item strong {
    font-weight: 700;
}

.card__line {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    margin-bottom: 25px;
}

.card__price {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
}

.card__price strong {
    font-weight: 700;
    font-size: 24px;
}

.card__price .red {
    color: red;
    font-weight: 700;
    font-size: 24px;
}

.card__price span {
    font-weight: 400;
    font-size: 15px;
}

.compare-btn {
    padding: 5px 7px;
    border-radius: 5px;
    transition: all 0.5s ease;
}

.compare-btn:hover {
    background-color: #dfdfdf;
}

.card__button {
    width: 100%;
    padding: 10px;
    background: #eee;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1rem;
    border-radius: 5px;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.card__button:hover {
    background: #cccbcb;
}

.card__button:active {
    background: #aaa;
    -webkit-transition: all 0.01s;
    transition: all 0.01s;
}

.card__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.card__button-mini {
    width: 100%;
    padding: 10px 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    border: 1px solid #ccc;
    font-size: 14px;
    margin-bottom: 20px;
    margin: 0 10px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.card__button-mini:hover {
    background: #ccc;
}

.card__button-mini:active {
    background: #aaa;
    -webkit-transition: all 0.01s;
    transition: all 0.01s;
}

.card__add a {
    font-size: 14px;
    display: inline-block;
    color: #1e87f0;
    border-bottom: 1px dotted #1e87f0;
    margin-right: 10px;
}

.card__add span {
    font-size: 17px;
    color: #000;
    border-bottom: 1px dotted #000;
}

.cards__button {
    padding: 10px 25px;
    border-radius: 8px;
    background: #f3bb04;
    color: #eee;
    margin: 25px auto;
    display: block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.cards__button:hover {
    opacity: 0.7;
    color: #fff;
}

.cards__button:active {
    opacity: 0.3;
    -webkit-transition: all 0.01s;
    transition: all 0.01s;
}

.material-symbols-outlined {
    font-size: 16px;
}

.pageBody {
    padding-top: 50px;
    max-width: 1050px;
    margin-right: 0;
}

textarea {
    height: 250px;
    width: 100%;

    background-color: #e0e0e0;
    padding: 20px;
}

@media (max-width: 678px) {
    .card {
        margin: 10px;
    }
}

.form-block {
    width: 100%;
    padding: 40px 0;
    background: url(../assets/img/form-fon.webp) center/cover no-repeat;
}

.forms {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
}

.form {
    text-align: center;
    padding: 20px 10px 40px;
    background: #fff;
    max-width: 560px;
    margin: 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 45%;
    flex: 1 1 45%;
}

.form__title {
    font-size: 24px;
}

.form__item-name {
    margin: 20px;
    font-size: 16px;
}

.form__item-name-row {
    margin-top: 80px;
}

.form__item {
    margin: 20px 10px;
}

.form__item input,
.form__item select {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #bbb;
}

.form__item input:focus,
.form__item select:focus {
    border: 1px solid #0471d6;
    background: #eee;
}

@media (max-width: 678px) {
    .form__list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.form__list .form__item {
    position: relative;
}

.form__list .form__item::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
}

.form__list .form__item:nth-child(1)::after {
    background: url(../assets/icons/person-green.svg) center/cover no-repeat;
}

.form__list .form__item:nth-child(2)::after {
    background: url(../assets/icons/smartphone-green.svg) center/cover no-repeat;
}

.form__list .form__item:nth-child(3)::after {
    background: url(../assets/icons/e-mail-green.svg) center/cover no-repeat;
}

.form__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.form__button {
    padding: 10px 30px;
    background: #f3bb04;
    color: #eee;
    margin: 0 auto;
    display: block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.form__button:hover {
    opacity: 0.7;
}

.form__button:active {
    opacity: 0.3;
    -webkit-transition: all 0.01s;
    transition: all 0.01s;
}

.cards-slider {
    padding: 60px 0;
    background: #fff;
}

.cards-slider .container {
    text-align: center;
}

.cards-slider__title {
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px;
    margin-bottom: 40px;
}

.cards-slider__title span {
    width: 100%;
    height: 1px;
    background: #eee;
    position: absolute;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    display: block;
}

.cards-slider__title h3 {
    position: relative;
    z-index: 2;
    padding: 0 10px;
}

.cards-slider__cards > .swiper-slide {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.cards-swiper__button {
    padding: 10px 30px;
    background: #f3bb04;
    color: #eee;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
}

.cards-swiper__button:hover {
    opacity: 0.7;
}

.cards-swiper__button:active {
    opacity: 0.3;
    -webkit-transition: all 0.01s;
    transition: all 0.01s;
}

.text-call-me {
    margin: 20px 15px;
}

.advantages {
    padding: 60px 10px;
    text-align: center;
    color: #333;
}

.advantages__row {
    padding-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.advantages__item {
    padding: 10px;
}

.advantages__img {
    width: 100%;
    max-width: 86px;
    height: 86px;
    margin: 20px auto;
}

.advantages__name {
    max-width: 150px;
    margin: 0 auto;
    line-height: 130%;
    font-size: 20px;
}

.advantages__descr {
    margin-top: 20px;
    max-width: 300px;
    margin: 0 auto;
    line-height: 130%;
}

.clients {
    padding: 60px 10px;
    text-align: center;
    color: #333;
}

.clients__row {
    padding-top: 20px;
}

.clients__item {
    max-width: 320px;
    margin: 10px auto;
    padding: 0 10px 10px 10px;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.clients__img {
    width: 100%;
    margin: 20px auto;
}

.clients__img img {
    width: 100%;
    height: 200px;
}

.clients__name {
    margin: 0 auto;
    line-height: 160%;
    font-size: 20px;
    display: block;
    color: #1e87f0;
}

@media (max-width: 520px) {
    .clients {
        display: none;
    }
}

.clients-swiper-slide .swiper-slide {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (min-width: 1100px) {
    .clients div.swiper-button-next::after,
    .clients div.swiper-button-prev::after {
        display: none;
    }

    .clients .swiper-pagination {
        display: none;
    }
}

.clients .map iframe {
    width: 100%;
}

.clients .map iframe div {
    width: 100%;
}

.footer {
    background: url(../assets/img/footer-fon.jpg) center/cover no-repeat;
    width: 100%;
    color: #fff;
}

.footer .container {
    padding: 0 0;
}

.footer__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.footer__block {
    padding: 20px 15px;
    min-width: 260px;
    max-width: 360px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    margin: 0 auto;
}

.footer__block-aksiya {
    background-color: #f3bb04;
    padding: 20px;
}

.footer__block-map {
    padding: 20px;
    max-width: 330px;
    width: 100%;
    background-color: #43d0d45d;
}

.footer__title {
    color: #fff;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.footer__line {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px solid #555;
}

.footer__list {
    margin-bottom: 10px;
}

.footer__item {
    line-height: 110%;
    margin: 10px 0;
}

.footer__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.footer__icon {
    width: 15px;
    height: 15px;
    margin: 5px;
}

.footer__icon img {
    width: 100%;
    height: 100%;
}

.change-my-color {
    fill: #fff;
}

.footer__block aside {
    overflow: auto;
}

.footer__link {
    color: #fff;
    transition: all 0.3s ease;
}

.footer__link:hover {
    color: #2b8ee0;
}

.map-address {
    display: flex;
    justify-content: center;
    /* width: 90%; */
}

::-moz-selection {
    color: #fff;
    background: #17a2b8;
}

::selection {
    color: #fff;
    background: #17a2b8;
}

.renge-wrapper {
    width: 100%;
    position: relative;
    color: #666;
}

.price-input {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 30px 0 35px;
    position: relative;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.price-input .field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
}

.field input {
    width: 130px;
    outline: none;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    color: #666;
    border: 1px solid #999;
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

div.slider {
    height: 9px;
    position: relative;
    background: #fff;
    margin: 0;
    border-radius: 2px;
    border: 1px solid #888;
}

.slider .progress {
    height: 100%;
    left: 25%;
    right: 25%;
    position: absolute;
    border-radius: 2px;
    background: #e9e9e9;
}

.range-input {
    position: relative;
}

.range-input input {
    position: absolute;
    width: 100%;
    height: 7px;
    top: -8px;
    left: 0;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background: #eee;
    border: 1px solid #aaa;
    pointer-events: auto;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background: #eee;
    border: 1px solid #aaa;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

/* Kliyentlar soni */
.digits {
    display: flex;
    align-items: center;
    max-width: 380px;
    width: 100%;
}

.digits-text {
    max-width: 170px;
    width: 100%;
    font-size: 18px;
    margin-right: 15px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0;
}

.some-digits {
    background-color: #f3bb04;
    padding: 4px 10px;
    font-size: 30px;
    height: 50px;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

/* Tooltip container */
.tooltips {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    cursor: pointer;
    /* If you want dots under the hoverable text */
}

.menu-item {
    padding: 5px 7px;
    border-bottom: 0.5px transparent;
    width: 100%;
}

.menu-item:hover {
    background-color: rgba(255, 238, 87, 0.541);
    border-bottom: 0.5px solid #2aabed;
}

/* Tooltip text */
.tooltips .tooltipstext {
    visibility: hidden;
    width: 160px;
    height: 65px;
    color: rgba(42, 41, 41, 0.938);
    text-align: center;
    padding-left: 5px;
    padding-right: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
    top: 100%;
    font-weight: bold;
    font-size: 14px;
    left: 30%;
    margin-left: -160px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
    background-color: #ffff;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltips:hover .tooltipstext {
    visibility: visible;
}

.tooltips .tooltipstexttwo {
    border-left: 1px solid rgb(163, 159, 159);
    visibility: hidden;
    width: 150px;
    height: 65px;
    color: rgba(42, 41, 41, 0.938);
    padding-left: 5px;
    padding-right: 14px;
    padding-bottom: 20px;
    top: 100%;
    font-weight: bold;
    font-size: 14px;
    left: 214%;
    margin-left: -125px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
    background-color: #ffff;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltips:hover .tooltipstexttwo {
    visibility: visible;
}

.tooltips .tooltipstexttwot {
    border-left: 1px solid rgb(163, 159, 159);
    visibility: hidden;
    width: 180px;
    height: 230px;
    color: rgba(42, 41, 41, 0.938);
    padding-left: 5px;
    padding-right: 14px;
    padding-top: 10px;
    top: 100%;
    font-weight: bold;
    padding-top: 13px;
    font-size: 15px;
    left: 284%;
    margin-left: -120px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
    background-color: #ffff;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltips:hover .tooltipstexttwot {
    visibility: visible;
}

.tooltips .tooltipstextt {
    visibility: hidden;
    width: 280px;
    height: 230px;
    color: rgba(42, 41, 41, 0.938);
    padding-left: 31px;
    padding-top: 14px;
    padding-right: 40px;
    top: 100%;
    font-weight: bold;
    font-size: 14px;
    margin-left: -170px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
    background-color: #ffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltips:hover .tooltipstexttt {
    visibility: visible;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.tooltips .tooltipstexttwott {
    border-left: 1px solid rgb(163, 159, 159);
    visibility: hidden;
    width: 180px;
    height: 140px;
    color: rgba(42, 41, 41, 0.938);
    padding-left: 5px;
    padding-right: 14px;
    padding-top: 10px;
    top: 100%;
    font-weight: bold;
    padding-top: 13px;
    font-size: 15px;
    left: 284%;
    margin-left: -120px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
    background-color: #ffff;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltips:hover .tooltipstexttwott {
    visibility: visible;
}

.tooltips .tooltipstexttt {
    visibility: hidden;
    width: 280px;
    /*height: 140px;*/
    color: rgba(42, 41, 41, 0.938);
    padding-left: 31px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 40px;
    top: 100%;
    font-weight: bold;
    font-size: 14px;
    margin-left: -170px;
    /* Use half of the width (120/2 = 60), to center the tooltip */
    background-color: #ffff;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    height: 140px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltips:hover .tooltipstextt {
    visibility: visible;
}

.about__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.about__title {
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px;
    margin-bottom: 20px;
}

.ant-modal-content {
    border-radius: 8px !important;
}

.sale-card {
    width: 25%;
    min-width: 300px;
    text-align: center;
    border: 1px solid rgba(241, 230, 71, 0.473);
    position: relative;
}

.abs {
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}

.sale-card_wrapper > span {
    flex-direction: column;
    display: block;
}

.sale-card_wrapper > h4 {
    text-align: center;
}

/* .ant-btn {
    position: fixed !important;
} */

.ant-btn > span {
    font-weight: bold;
}

.ant-btn-icon-only.ant-btn-lg {
    height: 53px !important;
    width: 53px !important;
}

.input-wrap {
    flex-direction: column;
    display: block;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 25px;
    width: 220px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.input-wrap input {
    font-size: 15px;
    width: 100%;
    height: 100%;
}

.input-wrap span {
    position: absolute;
    background-color: #f3bb04;
    z-index: 1;
    width: 29px;
    height: 99%;
    border-radius: 3px 0 0 3px;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    text-align: center;
    align-items: center;
    left: -23px;
    top: 0;
}

.more-btn {
    padding: 10px 30px;
    background: #f3bb04;
    color: #eee;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: block;
}

.card-price_sum {
    padding-top: 15px;
}

.card-price,
.card-price > span {
    font-size: 25px;
    padding-bottom: 15px;
}

.about {
    width: 100%;
    padding: 30px 0;
}

.about .container {
    width: 100%;
    display: flex;
}
.sifat_23{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.sifat_23 img{
    max-width: 420px;
    width: 100%;
}
.about__slider {
    width: 42%;
    padding-left: 40px;
}
.sifat1{
    width: 47%;
}
@media(min-width:0) and (max-width:820px){
    .sifat1{
        width: 95%;
    }  
    .sifat_23{
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .sifat_23 img{
        width: 90%;
    }

    .digits {
    display: flex;
    margin-left: 15px;
}
}
.im {
    width: 100%;
}

.pt50 {
    padding-top: 50px;
}

.about__title span {
    width: 100%;
    height: 1px;
    background: #eee;
    position: absolute;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    display: block;
}

.about__title h2 {
    position: relative;
    z-index: 2;
    background: #f8f8f8;
    padding: 0 10px;
}

.mySwiper {
    width: 100%;
}

.translator {
    margin-right: 12px;
    background: none;
}

.about__card {
    padding-left: 20px;
    background-color: #fff;
    width: 30%;
}

.about__text img {
    width: 35px;
    margin-top: 1px;
}

.about__text {
    margin-top: 10px;
    font-size: 22px;
}

.credit-button {
    cursor: pointer;
    padding: 10px 15px;
    background-color: #5bbbf3;
    color: #000000;
    font-weight: bold;
    border-radius: 5px;
    transition: all 0.5s ease;
    margin-left: 30px;
}

.credit-button:hover {
    color: #ffffff;
}

.main_container {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5%;
    padding-right: 5%;
}

.compare {
    width: 100%;
}

.team_list {
    align-items: center;
}

.team_list span {
    margin: 7px 3px 0px 5px;
}

@media (max-width: 1440px) {
    .about__slider {
        width: 50%;
    }

    .about__card {
        width: 50%;
    }

    .sale-card {
        width: 70%;
        margin: 0 auto;
        padding: 25px;
    }

    .about__title {
        margin: 0 10px;
    }
}

@media (max-width: 1400px) {
  .digits {
        display: block;
        margin-left: 20px;
    }
    .digits-text {
        font-size: 15px;
        margin-right: 0;
    }

    .some-digits {
        font-size: 18px;
        height: 36px;
    }
}

@media (max-width: 1170px) {
    .bth {
        padding: 5px 10px;
    }

    .digits-text {
        font-size: 15px;
        margin-right: 0;
    }

    .some-digits {
        font-size: 18px;
        height: 36px;
    }
}

@media (max-width: 979px) {
    .footer__block-map {
        padding: 10px !important;
        width: 800px !important;
        margin: 0 auto;
    }

    .footer__block-map > iframe {
        max-width: 800px !important;
        width: 100%;
    }

    .digits {
        display: flex;
        margin-left: 15px;
    }
}

@media (max-width: 768px) {
    .about__slider {
        width: 100%;
        margin: 0 auto;
    }

    .about__card {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .about__text {
        font-size: 18px;
    }

    .sale-card {
        width: 100%;
        margin: 0 auto;
        padding: 15px;
    }

    .about__title {
        margin: 0;
    }

    .card-price,
    .card-price > span {
        font-size: 20px;
    }

    .digits-text {
        font-size: 16px;
    }

    .some-digits {
        font-size: 20px;
        height: 40px;
    }
}

@media (max-width: 525px) {
    
    .digits-text {
        font-size: 15px;
        margin-right: 0;
    }

    .some-digits {
        font-size: 18px;
        height: 36px;
    }

    .card__price strong {
    font-weight: 500;
    font-size: 16px;
}

}

@media (max-width: 450px) {
    .footer__block-map {
        padding: 5px !important;
        width: 400px !important;
    }

    .footer__block-map > iframe {
        max-width: 400px !important;
        width: 100%;
    }
}

@media (max-width: 375px) {
    .bth {
       padding: 6px 10px; 
    }
    
}

.comment {
    width: 70%;
    margin: 0 auto;
    margin-top: 30px;
    padding-left: 2%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 2%;
    background-color: #ffff;
    box-shadow: rgba(83, 83, 83, 0.05) 0px 5px 15px;
    text-align: left;
}

.comment .comment_title {
    width: 100%;
    border-bottom: 1px solid rgb(209, 209, 209);
}

.comment_card {
    padding-top: 5px;
}

/* Editor style */

.demo-editor {
    border: 1px solid #c1c1c1;
    height: 300px;
}

/* Komiisia section */

.pages-container {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
}

.komissia-title {
    text-align: center;
    padding: 0 20px;
}

.komissia-wrap {
    justify-content: space-around;
    padding: 30px 15px;
    margin: 30px 0;
    border-radius: 5px;
    background: linear-gradient(
            180deg,
            rgba(234, 234, 234, 0) 0%,
            rgba(255, 242, 175, 0.54) 100%
    );
    box-shadow: 4px 4px 9px rgba(171, 165, 165, 0.25);
}

.material-symbols-rounded {
    font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 200, "opsz" 48;
}

.useful-wrap {
    padding: 50px 165px;
}

.useful-wrap > p {
    text-align: justify;
}

.useful-wrap > h3 {
    text-align: center;
}

.useful-wrap > span {
    display: block;
    text-align: center;
}

.komissia-img {
    display: block;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
}

.aksiya-img {
    display: block;
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
}


.komissia-list {
    text-align: justify;
    max-width: 1060px;
}

/* Vikup section */
.vikup-img {
    display: block;
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
}

.vikup-img_trade {
    display: block;
    max-width: 1250px;
    width: 100%;
}

.about-img {
    display: block;
    max-width: 830px;
    width: 100%;
    margin: 0 auto;
}

.about-img_guv {
    display: block;
    max-width: 430px;
    width: 100%;
    margin: 0 auto;
}

.vikup-card {
    text-align: center;
}

/* Compare table */

.ant-layout {
    max-width: 1602px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px;
}

td > button {
    padding: 7px 10px;
    border: none;
    outline: none;
    background-color: #c25034;
    border-radius: 7px;
    color: #fff;
}
.mb50{
    margin-bottom: 50px!important;
}
.narx-aniqla {
    margin-top: 90px !important;
}
.headers__icon-compare {
    width: 20px;
}
.slider__title span:last-child{
    padding-left: 30px;
}
.div_button input{
   color: red !important;
}
@media(min-width:0) and (max-width:1100.9px){
    .about-img {
        max-width: fit-content;
    }
}