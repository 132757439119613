* {
  box-sizing: border-box;
}

/* ==============
  General
===================*/
body {
  background-repeat: repeat;
  background: #f9f9f9;
  font-family: "Poppins", sans-serif;
  color: #354558;
  font-size: 15px;
}

.wr100 {
  width: 100%;
  height: 100vh !important;
  overflow: hidden;
}

.wrapperClassName {
  width: 80%;
  margin-inline: auto;
  border: 1px solid black;
  overflow: hidden;
  /*height: 500px;*/
}

.pb50 {
  padding-bottom: 50px;
}

/*.rdw-editor-main {*/
/*    height: fit-content;*/
/*    overflow: hidden;*/
/*}*/

.ant-modal .w100 {
  width: 100%;
}

.s-contain {
  width: 100%;
  padding: 20px;
}

.call-button {
  position: fixed !important;
  bottom: 50px;
  right: 45px;
  background-color: #f3bb04 !important;
}

.ant-slider-handle,
.ant-slider-track {
  background-color: #f3bb04 !important;
}

.sizeArticleImage {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
}

h1,
h2,
h3 
{
  margin: 10px 0;
  /* background-color: #fff; */
  font-weight: 400;
}

h6 {
  font-size: 14px;
}

p {
  line-height: 1.7;
}

svg {
  max-width: 100%;
}

* {
  outline: none !important;
}

a {
  color: midnightblue;
}

a:hover,
a:active,
a:focus {
  outline: 0;
  text-decoration: none;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: auto;
}

b,
strong {
  font-weight: 500;
}

.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#wrapper {
  height: 100%;
  overflow: hidden;
}

.btn-sm {
  padding: 7px 15px;
  font-size: 15px;
  color: #fff;
}

.content_wrapper {
  margin-left: 25%;
  margin-top: -7px;
}

.content_wrapper form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_wrapper h4 {
  font-size: 22px;
}

.admin_news_section {
  width: 80%;
  margin-left: 250px !important;
}

.search_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_form input {
  padding: 5px;
  width: 50px;
  height: 50px;
  background: none;
  border: 2px solid rgb(20, 125, 196);
  border-radius: 8px;
  box-sizing: border-box;
  font-family: "Open-Sans", Arial, Helvetica, sans-serif !important;
  font-size: 24px;
  color: #000;
  outline: none;
  transition: 0.5s;
}

@media(min-width:0) and (max-width:600.9px){
  .search_form input {
    display: none !important;
  }
  .deff{
    display: none;
  }
}
.box {
  position: absolute;
  margin-left: 15%;
  margin-top: -5px;
}

.box:hover .open {
  opacity: 0;
  z-index: -1;
}

.close {
  opacity: 0;
}

.deff {
  cursor: pointer;
  color: #fff;
  font-family: sans-serif;
  font-size: 17px;
  background: #1da5c7;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.5s ease;
}

.deff:hover {
  background: #005eb6;
  color: #d3efff;
}

.button_sumbit_news {
  font-size: 15px;
  padding: 5px 15px;
}

.news_form {
  width: 70%;
  margin: auto;
}

.slimScrollDiv {
  height: auto !important;
}

.dropdown-toggle::after {
  display: none !important;
}

/* ==============
  Bootstrap-custom
===================*/
.dropdown-menu {
  padding: 4px 0;
  font-size: 13px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  background-color: #ffffff;
  border-color: #e9ecef;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin: 0;
}

.innerDropDown {
  border-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding: 20px 0 !important;
}

.dropdown-toggle {
  color: rgba(51, 121, 183, 0.897) !important;
}

.dropdown-item {
  background-color: transparent;
  padding: 0.4rem 1rem;
  color: #fff !important;
}

/* .all_sc {
  color: #fff !important;
} */

/* .all_sc:hover {
  color: #3379b7ea !important;
} */

.dropdown-menu-animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  margin-top: 20px !important;
}

.show > .dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0px !important;
}

.unv_haqida_text {
  padding: 2rem;
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #9ca8b3;
  content: "\f105" !important;
  font-family: "Open-Sans";
  font-weight: 900;
}

.block_text {
  max-width: 290px;
  text-align: justify;
  text-transform: uppercase !important;
}

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid #e9ecef;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid #e9ecef;
  text-align: right;
}

.bg-primary {
  background-color: white !important;
  margin-top: 150px;
  color: midnightblue;
}

.card-heading {
  border: 1px solid midnightblue;
  box-shadow: midnightblue 0 0 15px 5px;
  border-radius: 15px;
}

.badge {
  font-weight: 500;
}

/* ==============
  Progressbar
===================*/
.progress-bar {
  background-color: #30419b;
}

/* ==============
  Pagination
===================*/
.page-link {
  color: #30419b;
}

.page-item.active .page-link {
  background-color: #30419b;
  border-color: #30419b;
}

.page-link:focus,
.page-link:hover {
  color: #354558;
  background-color: #f0f4f7;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  background: radial-gradient(
    rgba(0, 0, 0, 0.2) 0,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0) translate(0, 0);
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
  background: radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
}

.waves-effect.waves-classic .waves-ripple {
  background: rgba(0, 0, 0, 0.2);
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}

.waves-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.waves-button,
.waves-circle {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  /* -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); */
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: none;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1;
}

.waves-button {
  padding: 0.85em 1.1em;
  border-radius: 0.2em;
}

.waves-button-input {
  margin: 0;
  padding: 0.85em 1.1em;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper.waves-button {
  padding: 0;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
}

.waves-float {
  /* -webkit-mask-image: none; */
  -webkit-box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.waves-float:active {
  -webkit-box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
}

.waves-block {
  display: block;
}

/******* Metis Menu css *******/
.metismenu {
  padding: 0;
}

.metismenu ul {
  padding: 0;
}

.metismenu ul li {
  list-style: none;
}

/******* Topbar *******/

.topbar .topbar-left {
  padding-top: 20px;
  background-color: #4d70f1;
  float: left;
  text-align: center;
  height: 70px;
  position: fixed;
  max-width: 249px;
  width: 100%;
  z-index: 1;
}

.topbar .topbar-left .logo {
  line-height: 70px;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.topbar .topbar-left .logo .logo-sm {
  display: none;
}

.topbar .navbar-custom {
  background-color: #ffffff;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  margin-left: 240px;
  min-height: 70px;
  -webkit-box-shadow: 1px 0 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.05);
}

.topbar .navbar-custom .navbar-right .dropdown-toggle:after {
  content: initial;
}

.arrow-none:after {
  border: none;
  margin: 0;
  display: none;
}

.dropdown-menu-lg {
  width: 300px;
}

.app-search {
  position: relative;
  margin-top: 18px;
}

.app-search .form-control,
.app-search .form-control:focus {
  border: 1px solid #f0f4f7;
  font-size: 13px;
  height: 34px;
  padding-left: 18px;
  padding-right: 40px;
  margin-right: 16px;
  background: #f0f4f7;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 30px;
  width: 200px;
  border: none;
}

.app-search button {
  position: absolute;
  top: 9px;
  right: 26px;
  display: block;
  color: #9ca8b3;
  font-size: 11px;
  border: none;
  background-color: transparent;
}

.button-menu-mobile {
  border: none;
  color: #5b7798;
  display: inline-block;
  height: 70px;
  width: 60px;
  background-color: #ffffff;
  font-size: 24px;
}

/******* Sidemenu *******/
.side-menu {
  min-width: 240px;
  z-index: 10;
  background: #27b4a1;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  top: 70px;
}

.side-menu .waves-effect .waves-ripple {
  background-color: rgba(48, 65, 155, 0.4);
}

#sidebar-menu {
  padding-top: 10px;
}

#sidebar-menu > ul > li.mm-active > a > span > .menu-arrow i {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#sidebar-menu > ul > li > a {
  color: #020061;
  display: block;
  padding: 13px 20px;
  font-size: 14px;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

#sidebar-menu > ul > li > a:hover,
#sidebar-menu > ul > li > a:focus,
#sidebar-menu > ul > li > a:active {
  color: #fff;
  background-color: #05857a;
  text-decoration: none;
}

#sidebar-menu > ul > li > a > span {
  margin-left: 7px;
}

#sidebar-menu > ul > li > a i {
  line-height: 1;
  width: 33px;
  height: 33px;
  display: inline-block;
  vertical-align: middle;
}

#sidebar-menu > ul > li > a .badge {
  margin-top: 3px;
}

#sidebar-menu > ul > li > a.mm-active {
  color: #ffffff !important;
  background-color: midnightblue;
}

#sidebar-menu .menu-title {
  padding: 12px 20px !important;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #041777;
}

#sidebar-menu .submenu li.mm-active > a {
  color: #ffffff;
  background-color: #30419b;
}

#sidebar-menu .submenu li a {
  padding: 8px 20px 8px 10px;
  color: #7e8cda;
  display: block;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

#sidebar-menu .submenu li a:focus {
  background-color: left-bar;
}

#sidebar-menu .submenu li a:hover {
  background-color: #30419b;
  color: rgba(255, 255, 255, 0.8);
}

#sidebar-menu .submenu > li > a {
  padding-left: 50px;
}

#sidebar-menu .submenu > li .submenu > li > a {
  padding-left: 70px;
}

.waves-effect i {
  border-radius: 20%;
  padding: 3px 21px 3px 3px;
  font-size: 18px;
  text-align: center;
}

.download_efect i {
  border-radius: 20%;
  padding: 3px 21px 3px 3px;
  font-size: 18px;
  text-align: center;
}

.logo-light h2 {
  background-color: transparent;
}

@media (max-width: 480px) {
  .side-menu {
    z-index: 10 !important;
  }

  .button-menu-mobile {
    display: block;
  }

  .navbar-custom {
    margin-left: 0 !important;
  }
}

@media (max-width: 768px) {
  .topbar .topbar-left {
    width: 70px !important;
    height: 70px;
  }

  .topbar .topbar-left .logo-light {
    display: none !important;
  }

  .topbar .topbar-left .logo-sm {
    display: block !important;
    line-height: 70px !important;
    font-size: 28px;
  }

  .navbar-custom {
    margin-left: 70px !important;
  }

  .content-page {
    margin-left: 0 !important;
  }

  .content-page .content {
    padding: 0px;
  }

  .enlarged .left.side-menu {
    margin-left: -70px;
  }

  .footer {
    left: 0 !important;
  }
}

.right-sidebar {
  position: fixed;
  width: 280px;
  top: 70px;
  bottom: 0px;
  right: 0px;
  padding-bottom: 30px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
}

@media (min-width: 1200px) {
  .dasboard-content {
    margin-right: 280px;
  }
}

.page_box {
  display: flex;
  flex-wrap: wrap;
  margin-left: 25%;
  margin-top: -7px;
}

.page_box form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_list {
  width: 80%;
  margin-left: 250px !important;
}

.page_list_mobile {
  width: 100%;
  margin-left: 0 !important;
}

.page_body_admin h4 {
  font-size: 22px;
}

.page_form .col-sm-10 {
  width: 200px;
}

/* contact */
.tr_phone {
  max-width: 210px;
}

.contact-page-list {
  list-style-type: none !important;
}

.contact-mail-icon {
  width: 20px;
  margin-right: 7px;
}

.contact-adres-icon {
  width: 20px;
  height: 22px;
  margin-right: 7px;
}

/******* Content Page *******/
.content-page {
  margin-left: 240px;
  overflow: hidden;
}

.content {
  margin-left: 250px;
}

.content1024 {
  margin-left: 0px;
}

.content-page .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
  margin-bottom: 60px;
}

/******* Page Title *******/
.page-title-box {
  padding: 20px 0px;
}

.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 30px;
}

.page-title-box .breadcrumb {
  padding: 4px 0;
  background-color: transparent;
  margin-bottom: 0;
}

.page-title-box .breadcrumb a {
  color: #354558;
}

.page-title-box .breadcrumb a:hover {
  color: rgba(53, 69, 88, 0.9);
}

.page-title-box .breadcrumb .active {
  color: rgba(53, 69, 88, 0.7);
}

@media (max-width: 576px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}

@media (max-width: 420px) {
  .dropdown-menu-lg {
    width: 220px;
  }

  .notify-icon {
    display: none;
  }

  .notify-details {
    margin-left: 0px !important;
  }
}

/* ==============
  Alertify
===================*/
.alertify,
.alertify-logs {
  z-index: 99;
}

.alertify input {
  border: 2px solid #9ca8b3;
}

.alertify-logs > .success {
  background-color: #02c58d;
  color: #ffffff;
}

.alertify-logs > .error {
  background-color: #fc5454;
  color: #ffffff;
}

.alertify-logs > *,
.alertify-logs > .default {
  background-color: #30419b;
}

/* ==============
  Alertify
===================*/
.alertify,
.alertify-logs {
  z-index: 99;
}

.alertify input {
  border: 2px solid #9ca8b3;
}

.alertify-logs > .success {
  background-color: #02c58d;
  color: #ffffff;
}

.alertify-logs > .error {
  background-color: #fc5454;
  color: #ffffff;
}

.alertify-logs > *,
.alertify-logs > .default {
  background-color: #30419b;
}

/* =============
   Alerts
============= */
.alert {
  position: relative;
  border: 0;
}

.alert .alert-link {
  font-weight: 500;
  text-decoration: underline;
}

.alert-primary {
  color: #ffffff;
  background-color: #30419b;
}

.alert-primary hr {
  border-top-color: #243174;
}

.alert-success {
  color: #ffffff;
  background-color: #02c58d;
}

.alert-success hr {
  border-top-color: #019369;
}

.alert-danger {
  color: #ffffff;
  background-color: #fc5454;
}

.alert-danger hr {
  border-top-color: #fb2222;
}

.alert-warning {
  color: #ffffff;
  background-color: #fcbe2d;
}

.alert-warning hr {
  border-top-color: #f3ab03;
}

.alert-info {
  color: #ffffff;
  background-color: #59c6fb;
}

.alert-info hr {
  border-top-color: #27b5fa;
}

.alert-secondary {
  color: #ffffff;
  background-color: #616f80;
}

.alert-secondary hr {
  border-top-color: #4b5663;
}

.alert-dark {
  color: #ffffff;
  background-color: #354558;
}

.alert-dark hr {
  border-top-color: #222c38;
}

/* ==============
  Buttons
===================*/
.btn {
  border-radius: 3px;
  font-size: 10px;
}

button:focus {
  outline: none;
}

.btn-sm {
  font-size: 10.83333px;
}

.btn-lg {
  font-size: 15.6px;
}

.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-dark,
.btn-pink,
.btn-purple,
.btn-indigo,
.btn-teal,
.btn-lime,
.btn-orange,
.btn-brown,
.btn-blue-grey {
  color: #ffffff;
}

.btn-primary {
  background-color: #30419b;
  border: 1px solid #30419b;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary,
.btn-outline-primary.active,
.btn-outline-primary:active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-outline-primary:hover,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.btn-outline-primary:not([disabled]):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #2a3988;
  border: 1px solid #2a3988;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(48, 65, 155, 0.3);
  box-shadow: 0 0 0 2px rgba(48, 65, 155, 0.3);
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(53, 69, 88, 0.3);
  box-shadow: 0 0 0 2px rgba(53, 69, 88, 0.3);
}

.btn-success {
  background-color: #02c58d;
  border: 1px solid #02c58d;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success,
.btn-outline-success.active,
.btn-outline-success:active,
.show > .btn-outline-success.dropdown-toggle,
.btn-outline-success:hover,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus,
.btn-outline-success:not([disabled]):not(.disabled).active,
.btn-outline-success:not([disabled]):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #02ac7b;
  border: 1px solid #02ac7b;
}

.btn-success.focus,
.btn-success:focus,
.btn-outline-success.focus,
.btn-outline-success:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 197, 141, 0.3);
  box-shadow: 0 0 0 2px rgba(2, 197, 141, 0.3);
}

.btn-info {
  background-color: #59c6fb;
  border: 1px solid #59c6fb;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info,
.btn-outline-info.active,
.btn-outline-info:active,
.show > .btn-outline-info.dropdown-toggle,
.btn-outline-info:hover,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle,
.btn-outline-info:not([disabled]):not(.disabled).active,
.btn-outline-info:not([disabled]):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: #40bdfa;
  border: 1px solid #40bdfa;
}

.btn-info.focus,
.btn-info:focus,
.btn-outline-info.focus,
.btn-outline-info:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(89, 198, 251, 0.3);
  box-shadow: 0 0 0 2px rgba(89, 198, 251, 0.3);
}

.btn-warning {
  background-color: forestgreen;
  border: 1px solid forestgreen;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning,
.btn-outline-warning.active,
.btn-outline-warning:active,
.show > .btn-outline-warning.dropdown-toggle,
.btn-outline-warning:hover,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle,
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle,
.btn-outline-warning:not([disabled]):not(.disabled).active,
.btn-outline-warning:not([disabled]):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  background-color: midnightblue;
  border: 1px solid midnightblue;
  color: #ffffff;
}

.btn-warning.focus,
.btn-warning:focus,
.btn-outline-warning.focus,
.btn-outline-warning:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(252, 190, 45, 0.3);
  box-shadow: 0 0 0 2px rgba(252, 190, 45, 0.3);
}

.btn-danger {
  background-color: #fc5454;
  border: 1px solid #fc5454;
}

.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger,
.btn-outline-danger.active,
.btn-outline-danger:active,
.show > .btn-outline-danger.dropdown-toggle,
.btn-outline-danger:hover,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle,
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle,
.btn-outline-danger:not([disabled]):not(.disabled).active,
.btn-outline-danger:not([disabled]):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: #fc3b3b;
  border: 1px solid #fc3b3b;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-outline-danger.focus,
.btn-outline-danger:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(252, 84, 84, 0.3);
  box-shadow: 0 0 0 2px rgba(252, 84, 84, 0.3);
}

.btn-dark {
  background-color: #354558;
  border: 1px solid #354558;
  color: #ffffff;
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus,
.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover,
.open > .dropdown-toggle.btn-dark,
.btn-outline-dark.active,
.btn-outline-dark:active,
.show > .btn-outline-dark.dropdown-toggle,
.btn-outline-dark:hover,
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle,
.btn-outline-dark:not([disabled]):not(.disabled).active,
.btn-outline-dark:not([disabled]):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  background-color: #2b3948;
  border: 1px solid #2b3948;
  color: #ffffff;
}

.btn-dark.focus,
.btn-dark:focus,
.btn-outline-dark.focus,
.btn-outline-dark:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(53, 69, 88, 0.3);
  box-shadow: 0 0 0 2px rgba(53, 69, 88, 0.3);
}

.btn-secondary {
  background-color: #616f80;
  border: 1px solid #616f80;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover,
.open > .dropdown-toggle.btn-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary:active,
.show > .btn-outline-secondary.dropdown-toggle,
.btn-outline-secondary:hover,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-outline-secondary:not([disabled]):not(.disabled).active,
.btn-outline-secondary:not([disabled]):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #566271;
  border: 1px solid #566271;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(97, 111, 128, 0.3);
  box-shadow: 0 0 0 2px rgba(97, 111, 128, 0.3);
}

.btn-link {
  color: #354558;
}

.btn-link:hover {
  color: #30419b;
}

/* button Outline */
.btn-outline-primary {
  color: #30419b;
  border-color: #30419b;
}

.btn-outline-success {
  color: #02c58d;
  border-color: #02c58d;
}

.btn-outline-info {
  color: #59c6fb;
  border-color: #59c6fb;
}

.btn-outline-warning {
  color: midnightblue;
  border-color: midnightblue;
}

.btn-outline-danger {
  color: #fc5454;
  border-color: #fc5454;
}

.btn-outline-dark {
  color: #354558;
  background-image: none;
  background-color: transparent;
  border-color: #354558;
}

.btn-outline-secondary {
  color: #616f80;
  border-color: #616f80;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-icon {
  position: relative;
}

.btn-icon .btn-icon-label {
  margin: -0.55rem 0.9rem -0.55rem -0.9rem;
  padding: 0.6rem 0.9rem;
}

.btn-icon::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 38%;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-icon:hover::before {
  width: 100%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%);
}

/* ==============
  Card
===================*/
.card {
  border: none;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
  box-shadow: 0px 0px 13px 0px rgb(191 191 191 / 44%);
  margin-bottom: 30px;
  transition: all 0.5s ease;
}

.card_team {
  max-height: 610px;
}

.card:hover {
  box-shadow: 0px 0px 13px 0px rgba(141, 141, 141, 0.44);
}

.ant-row {
  justify-content: center;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-gap: 30px;
    column-gap: 30px;
  }
}

.card-columns .card {
  margin-bottom: 30px;
}

/* =============
   Nestable
============= */
.custom-dd .dd-list .dd-item .dd-handle {
  background: #f0f4f7;
  border: 1px solid #f0f4f7;
  padding: 10px 16px;
  height: auto;
  font-size: 14px;
  font-weight: normal;
  border-radius: 3px;
}

.custom-dd .dd-list .dd-item .dd-handle:hover {
  color: #30419b;
}

.custom-dd .dd-list .dd-item button {
  height: auto;
  font-size: 25px;
  margin: 8px auto;
  color: #9ca8b3;
  width: 35px;
}

.custom-dd-empty .dd-list .dd3-handle {
  border: none;
  background: #dee2e6;
  height: 36px !important;
  width: 36px !important;
}

.custom-dd-empty .dd-list .dd3-handle:before {
  color: inherit;
  top: 7px;
}

.custom-dd-empty .dd-list .dd3-handle:hover {
  color: #30419b;
}

.custom-dd-empty .dd-list .dd3-content {
  height: auto;
  border: none;
  padding: 8px 16px 8px 46px;
  background: #dee2e6;
}

.custom-dd-empty .dd-list .dd3-content:hover {
  color: #30419b;
}

.custom-dd-empty .dd-list button {
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-weight: 600;
}

.dd-dragel > .dd-item .dd-handle {
  padding: 8px 16px !important;
  background: #dee2e6;
  height: auto;
}

.dd-placeholder,
.dd-empty {
  background: #dee2e6;
  border: 1px dashed #9ca8b3;
}

.dd-dragel > .dd3-item > .dd3-handle {
  border: none;
  background: #dee2e6;
  height: 36px !important;
  width: 36px !important;
}

.dd-dragel > .dd3-item > .dd3-handle:before {
  color: inherit;
  top: 7px;
}

.dd-dragel > .dd3-item > .dd3-content {
  padding: 8px 16px 8px 46px;
  background: #dee2e6;
  height: auto;
}

/* Ion Range slider */
.irs--modern .irs-bar,
.irs--modern .irs-to,
.irs--modern .irs-from,
.irs--modern .irs-single,
.irs--modern .irs-handle > i:first-child {
  background: #30419b !important;
}

.irs--modern .irs-to:before,
.irs--modern .irs-from:before,
.irs--modern .irs-single:before {
  border-top-color: #30419b;
}

.irs--modern .irs-min,
.irs--modern .irs-max {
  color: #616f80;
}

.irs--modern .irs-grid-text {
  font-size: 10px;
}

/* =========== */
/* Sweet Alert2 */
/* =========== */
.swal2-modal .swal2-title {
  font-size: 24px;
}

.swal2-modal .swal2-content {
  font-size: 16px;
}

.swal2-modal .swal2-spacer {
  margin: 10px 0;
}

.swal2-modal .swal2-file,
.swal2-modal .swal2-input,
.swal2-modal .swal2-textarea {
  border: 2px solid #9ca8b3;
  font-size: 16px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.swal2-modal .swal2-file:focus,
.swal2-modal .swal2-input:focus,
.swal2-modal .swal2-textarea:focus {
  outline: 0;
  border: 2px solid #30419b;
}

.swal2-popup .swal2-styled:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.swal2-icon.swal2-question {
  color: #30419b;
  border-color: #30419b;
}

.swal2-icon.swal2-success {
  border-color: #02c58d;
}

.swal2-icon.swal2-success .line {
  background-color: #02c58d;
}

.swal2-icon.swal2-success .placeholder {
  border-color: #02c58d;
}

.swal2-icon.swal2-warning {
  color: #fcbe2d;
  border-color: #fcbe2d;
}

.swal2-icon.swal2-error {
  border-color: #fc5454;
}

.swal2-icon.swal2-error .line {
  background-color: #fc5454;
}

/* ==============
  Form-elements
===================*/
label {
  font-weight: normal !important;
}

.form-control {
  font-size: 13px;
}

.form-control:focus {
  border-color: #30419b;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #30419b;
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #30419b;
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #30419b;
}

.has-success .form-control {
  border-color: #02c58d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-warning .form-control {
  border-color: #fcbe2d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-danger .form-control {
  border-color: #fc5454;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-group-addon {
  border-radius: 2px;
  border: 1px solid #f0f4f7;
}

.input-group-text {
  font-size: 13px;
}

/* ==============
  Form-Validation
===================*/
.error {
  color: #ff0000;
}

.parsley-error {
  border-color: #fc5454;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}

.parsley-errors-list.filled {
  display: block;
}

.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #fc5454;
  margin-top: 5px;
}

/* ==============
  Form-Advanced
===================*/
/* Datepicker */
.datepicker {
  border: 1px solid #ced4da;
  padding: 8px;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover {
  background-color: #30419b !important;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
}
.ant-input:placeholder-shown{
  font-size: 15px !important;
}
.ant-input-affix-wrapper > .ant-input:not(textarea){
  font-size: 15px !important;
}
.table-condensed thead th,
.table-condensed tbody td {
  padding: 5px;
}

/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
  padding: 9px 12px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  top: 4px;
  left: 8px;
}

.input-group-addon {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

/* Prism */
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #f0f4f7;
}

/* Rating */
.badge:empty {
  padding: 0;
}

/* ==============
  Form Editor
===================*/
.mce-panel {
  border-color: #dfe8ee !important;
  background-color: #f0f4f7 !important;
}

.mce-menu-item:hover,
.mce-menu-item.mce-selected,
.mce-menu-item:focus {
  background-color: #30419b !important;
}

.mce-menu {
  background-color: #ffffff !important;
}

/* ==============
  Widgets
===================*/
.widget-chart li {
  width: 31.5%;
  display: inline-block;
  padding: 0;
}

.widget-chart li i {
  font-size: 22px;
}

.mini-stat-icon i {
  font-size: 30px;
  width: 58px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}

.dashboard-ct-chart .ct-grid {
  stroke: rgba(0, 0, 0, 0.05);
}

#slider-1 {
  height: 49px;
}

#slider-2 {
  height: 49px;
}

.suggestion-icon i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-color: #30419b;
  font-size: 15px;
}

/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: #ffffff;
  z-index: 100;
  color: #354558;
  -webkit-box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.14);
  box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.14);
  border-radius: 1px;
}

/* ==============
  Tables
===================*/
.table {
  margin-bottom: 10px;
}

.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > thead > tr > td {
  padding: 15px 12px;
}

.table-hover tbody tr:hover,
.table-striped tbody tr:nth-of-type(odd),
.thead-default th {
  background-color: #f0f4f7;
}

.table td,
.table th {
  vertical-align: middle;
}

/************** datatables ***************/
.dataTables_wrapper.container-fluid {
  width: auto;
}

/* == Responsive Table ==*/
table.focus-on tbody tr.focused th {
  background-color: #30419b;
  color: #ffffff;
}

table.focus-on tbody tr.focused td {
  background-color: #30419b;
  color: #ffffff;
}

@media (min-width: 1400px) {
  .btn {
    font-size: 14px;
  }

  .header_nav_link {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .title img {
    display: none;
  }
}

@media (max-width: 375px) {
  .date {
    display: none;
  }
}

@media (max-width: 768px) {
  .mo-mb-2 {
    margin-bottom: 10px;
  }

  .mo-mt-2 {
    margin-top: 10px !important;
  }

  .ex-pages {
    padding: 24px 0px;
  }

  .btn-toolbar {
    margin-top: 15px;
  }
}

.home-btn {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 1;
}

.accountbg {
  background-image: url("./images/login-bg.png");
  position: absolute;
  background-position: center center;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  /* background-color: #dbc33c; */
}

.accountbg1 {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  background-position: center center;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  /* background-color: #dbc33c; */
}

.welcome-text {
    color: #fff;
    background-color: transparent;
}

.form_login {
    background-color: rgb(0 155 255 / 52%);
    color: #fff;
    max-width: 420px;
    text-align: center;
    margin: 0px auto;
    padding: 20px 30px 15px 30px;
    border-radius: 10px;
}

.form-group label{
  color: #000 !important;
}

.card-pages {
    border-radius: 10px;
    background-color: rgb(0 119 204 / 55%);;
  box-shadow: 3px 7px 57px -16px rgba(72, 43, 255, 0.84);
  -webkit-box-shadow: 3px 7px 57px -16px rgba(72, 43, 255, 0.84);
  -moz-box-shadow: 3px 7px 57px -16px rgba(72, 43, 255, 0.84);
}

.me-2 {
  outline: none;
  border: none;
}

.hello-img {
    max-width: 260px;
    margin: 20px;
}

.wrapper-page {
  margin: 2% auto;
  width: 420px;
  position: relative;
}

.demo-wrapper {
  height: 250px;
  width: 70%;
}

@media (max-width: 767px) {
  .wrapper-page {
    width: 90%;
  }
}

.box {
  position: absolute;
  margin-left: 30%;
  margin-top: -5px;
}

.box:hover input {
  width: 350px;
  background: #fff;
  border-radius: 5px;
}

.box:hover .open {
  opacity: 0;
  z-index: -1;
}

.close {
  opacity: 0;
}

.announcements_list {
  margin-left: 250px;
  width: 80%;
}

.announcements_list .box {
  margin-left: 25%;
  margin-top: -7px;
}

.announcements_list form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.announcements_list a {
  padding: 7px 15px;
  font-size: 15px;
  color: #fff;
}

.announcements_list h4 {
  font-size: 22px;
}

.announcements_list2 {
  margin-left: 250px;
  width: 80%;
}

.download {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  position: relative;
  top: 35px;
}

.files {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  margin-top: 45px;
}

.files a {
  text-decoration: none;
  list-style-type: none;
  color: midnightblue;
}

.upload_button button {
  width: 130px;
  height: 35px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid midnightblue;
}

.upload_button button a {
  text-decoration: none;
  list-style-type: none;
  color: midnightblue;
}

.upload_button_text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: gray;
  margin-left: 140px;
  margin-top: -32px;
}

.max_size {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  margin-left: 10px;
  margin-top: 25px;
}

.upload_button_2 button {
  width: 100px;
  height: 35px;

  border-radius: 5px;
  border: 1px solid midnightblue;
}

.upload_button_2 a {
  color: midnightblue;
  font-family: sans-serif;
}

.download {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  position: relative;
  top: 35px;
}

.files {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  margin-top: 45px;
}

.files a {
  text-decoration: none;
  list-style-type: none;
  color: midnightblue;
}

.upload_button button {
  width: 130px;
  height: 35px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid midnightblue;
}

.upload_button button a {
  text-decoration: none;
  list-style-type: none;
  color: midnightblue;
}

.upload_button_text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: gray;
  margin-left: 140px;
  margin-top: -32px;
}

.max_size {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  margin-left: 10px;
  margin-top: 25px;
}

.upload_button_2 button {
  width: 100px;
  height: 35px;

  border-radius: 5px;
  border: 1px solid midnightblue;
}

.upload_button_2 a {
  color: midnightblue;
  font-family: sans-serif;
}

.video_yuklash_wrapper {
  margin-left: 420px;
  margin-top: -310px;
}

.download {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  position: relative;
  top: 35px;
}

.btn-admin {
  height: 100px;
}

.files {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  margin-top: 45px;
}

.files a {
  text-decoration: none;
  list-style-type: none;
  color: midnightblue;
}

.upload_button button {
  width: 130px;
  height: 35px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid midnightblue;
  box-shadow: midnightblue 0 0 15px 5px;
  background-color: #fff;
}

.upload_button button a {
  text-decoration: none;
  list-style-type: none;
  color: midnightblue;
}

.upload_button_text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: gray;
  margin-left: 140px;
  margin-top: -32px;
}

.max_size {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  /* grayscale / black */
  color: midnightblue;
  margin-left: 10px;
  margin-top: 25px;
}

.upload_button_2 button {
  width: 100px;
  height: 35px;
  box-shadow: midnightblue 0 0 15px 5px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid midnightblue;
}

.upload_button_2 a {
  color: midnightblue;
  font-family: sans-serif;
}

.rasm_yuklash_wrapper {
  margin-left: 840px;
  margin-top: -310px;
}

.upload_button_3 {
  margin-top: 15px;
}

.upload_button_3 button {
  width: 100px;
  height: 35px;
  box-shadow: midnightblue 0 0 15px 5px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid midnightblue;
}

.team-img-two {
  width: 100%;
  object-position: center;
  object-fit: cover;
  height: 400px;
}

.upload_button_3 a {
  color: midnightblue;
  font-family: sans-serif;
}

.loader {
  width: 100vw;
  z-index: 10000;
  height: 100vh;
  background-color: rgb(241, 238, 238);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader img {
  z-index: 5000;
  margin-left: 45%;
  margin-top: 17%;
}

.upload_wrapper {
  width: 80%;
  margin-left: 250px;
  justify-content: space-around;
}

.statistics_wrapper {
  width: 80%;
  margin-left: 250px;
  justify-content: space-around;
  padding-top: 50px !important;
}

ul.show {
  padding: 0;
  margin: 0;
}

.social_icon_container {
  display: flex;
  width: 100%;
}

.social_icon {
  margin-right: 7px;
  padding: 7px;
  width: 40px;
  border-radius: 8px;
  transition: all 0.5s ease;
}

.social_icon:hover {
  background-color: #001aff;
}

.social_icon_yt {
  padding: 7px;
  width: 50px;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.w30 {
  width: 30%;
}

.w70 {
  width: 70%;
}

.mainWrapper {
  display: flex;
  width: 100%;
  padding-top: 50px;
}

.sp-img {
  width: 250px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ExcelTable2007 {
  border: 1px solid #b0cbef;
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 10px;
  /*height: 100vh;*/
  /*overflow: scroll;*/
}

.ExcelTable2007 TH {
  background-repeat: repeat-x;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #9eb6ce;
  border-width: 0px 1px 1px 0px;
  height: 17px;
  line-height: 17px;
}

.ExcelTable2007 TD {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #d0d7e5;
  border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable2007 TD.heading {
  background-color: #e4ecf7;
  text-align: center;
  border: 1px solid #9eb6ce;
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

/*TH {*/
/*    text-align: center;*/
/*}*/

.jumbotron-background {
  border-radius: 0rem !important;
  color: white;
}

.jumbotron-button {
  color: #fff !important;
  background-color: transparent !important;
  border-color: lightgrey !important;
}

.jumbotron-button:hover {
  color: #fff !important;
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.invisible {
  display: none !important;
}

.wre {
  padding-top: 100px;
  width: 80%;
  margin-left: 250px !important;
}

.cn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fg {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  width: 300px;
  flex-direction: column;
  gap: 15px;
}

.ml200 {
  margin-left: 300px !important;
}
.ant-slider-handle{
  border-color: #d9d9d9 !important;
}

.ant-slider-handle {
  border-radius: 0 !important;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
  background-color: transparent !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
  background-color: transparent !important;
}

.cf {
  display: flex;
  justify-content: space-between;
}

.wb100 {
  width: 100% !important;
  margin: 0 20%;
}

.cimg {
  width: 200px;
}

.thw {
  justify-content: flex-start !important;
  flex-direction: row;
  align-items: flex-start !important;
}

.wcw {
  width: 100%;
  padding: 0 5%;
}

.divider {
  text-transform: uppercase;
  font-size: 32px !important;
}

.w-box {
  display: flex;
  gap: 30px;
  padding: 50px 5% 0 5%;
  margin-bottom: 40px;
}

.w-b1 {
  width: 50%;
  padding: 20px;
  background-color: white;
}

.t1 {
  font-size: 1.5rem;
  line-height: 1.4;
  color: #333;
}

.n1 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bolder;
}

.tr1 li {
  list-style-type: disc;
}

.inp {
  padding: 7px !important;
  font-size: 16px !important;
}

@media (max-width: 800px) {
  .w-box {
    flex-direction: column !important;
  }

  .w-b1 {
    width: 100% !important;
  }
}

.excelWr {
  margin-left: 350px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.excelRow {
  display: flex;
  gap: 25px;
}

.ertw {
  min-width: 600px;
  overflow-x: scroll;
}

.w456 {
  width: 100%;
  overflow-x: scroll;
}

.df {
  display: flex;
  width: 100%;
}

.ml20 {
  margin-left: 20px;
}

.secP {
  display: flex; /* 1 */
  align-items: baseline; /* 2 */
  margin-bottom: 10px;
  padding: 0 20px 0 0;
}

.secP > * {
  padding: 0;
  margin: 0;
}

.secP .span {
  flex: 1; /* 3 */
  overflow: hidden;
  border-bottom: 2px dotted #333; /* 4 */
  margin: 0 3px;
}

.bth {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 7px 35px;
}

.lwr img {
  width: 170px;
  height: 71.7px;
}

.linkswr {
  display: flex;
  gap: 15px;
}

.inLyu:after {
  content: "";
  display: block;
  border-bottom: 5px solid #f3bb04;
  width: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  border-radius: 2px;
}

.inLyu:hover:after {
  width: 100%;
}

.lyu {
  display: flex;
}

.inLyu {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  cursor: pointer;
}

.iop {
  display: flex;
  width: 36px;
  height: 36px;
  background: #bbb;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  transition: 0.3s;
}

.treww {
  display: flex;
  color: #4d4d4d;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.iconCol {
  color: #838383 !important;
}

.inLyu:hover .iop {
  opacity: 1;
}

.absch {
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 60px;
  left: -50px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  display: none;
}

.labsch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid #838383;
}

.rabsch {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.ltr {
  font-size: 14px;
  min-width: 150px;
  width: 100%;
  padding: 2px;
  color: #191970;
  border-bottom: 0.5px solid transparent;
  font-weight: 600;
}

.wwd {
  min-width: 200px;
}

.ltr:hover {
  background-color: rgba(255, 238, 87, 0.541);
  border-bottom: 0.5px solid #2aabed;
}

.ltr:hover {
  background-color: rgba(255, 238, 87, 0.541);
  border-bottom: 0.5px solid #2aabed;
}

.inLyu:hover .absch {
  display: flex;
}

@media (max-width: 1200px) {
  .team-img-two {
    height: 300px;
  }
  .card__item {
    line-height: 150%;
    font-size: 14px !important;
    margin: 0 0;
  }
  
  .bth {
       padding: 6px 6px; 
    }
}

@media only screen and (min-width: 1051px) and (max-width: 1110px){
  .digits {
    display: none;
  }
}

@media (max-width: 1050px) {
  .linkswr {
    display: none;
  }
  .lwr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .useful-wrap {
    padding: 50px 5%;
  }

  .headers__icons {
    max-width: 133px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .df {
    flex-direction: column-reverse;
    gap: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
  }
}
.mr10 {
  margin-right: 10px;
}
.mr11 {
  margin-right: 10px !important;
  color: white !important;
}
.mr15 {
  margin-right: 15px;
}
.pinfo {
  margin-right: 50px;
}
.card__item {
  display: flex;
  align-items: center !important;
}

@media (max-width: 420px) {
  .card__item {
    line-height: 150%;
    font-size: 13px !important;
    margin: 0 0;
  }

  .ant-btn-icon-only.ant-btn-lg {
    height: 43px!important;
    width: 43px !important;
}



  .bth {
       padding: 6px 10px; 
    }
}
.textarean {
  width: 70%;
}
.gap30 {
  display: flex;
  gap: 40px;
}
@media (max-width: 575px) {
  .gap30 {
    flex-direction: column;
    gap: 10px;
  }
  .textarean {
    width: 100%;
  }
}
@media screen and (max-width: 1000.9px) {
  .admin_news_section,
  .page_list{
    width: 95%;
    margin: 0 auto !important;
  }
  .d-flex{
    flex-direction: column;
  }
  .admin_news_section .row{
    padding-top: 2rem !important;
  }
   #sidebar-menu > ul > li > a{
    font-size: 25px;
    color: #fff;
  }

  .statistics_wrapper{
    margin-left: 77px;
    width: 100%;
    margin-left: 0;
  }

  #sidebar-menu > ul > li > a:hover{
    background-color: rgb(5 133 122 / 50%);
  }

}
@media (min-width:0) and (max-width:600.9px){
  .announcements_list {
    margin-left: 0;
    width: 100%;
  }
  .announcements_list h4{
    margin-top: 3rem !important;
  }
  .excelWr{
    margin-left: 10px;
    padding-top: 50px;
  }
  .statistics_wrapper{
    margin-left: 77px;
    width: 100%;
    margin-left: 0;
  }
}