.calData {
  width: 450px;
  padding: 25px;
  box-shadow: 0 0 10px -5px #696969;
  border-radius: 5px;
}
.calData__item h1 {
  color: #00805f;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: -3px;
}
.calData__item span {
  color: #95847f;
}
.calData button {
  color: #fff;
  font-weight: bold;
  background-color: #f3bb04;
  border-radius: 8px;
  padding: 15px 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.firstCalculate {
  font-weight: bold;
  font-size: 23px;
  color: red !important;
}

@media (max-width: 576px) {
  .calData {
    width: 350px;
    padding: 15px;
  }
  .calData__item h1 {
    color: #00805f;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: -3px;
  }
}

@media (max-width: 768px) {
  .calData {
    width: 370px;
    padding: 20px;
  }
  .calData__item h1 {
    color: #00805f;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: -3px;
  }

  .calData button {
  color: #fff;
  font-weight: bold;
  background-color: #f3bb04;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 15px 0;
}
}